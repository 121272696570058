<template>
    <layout>
        <page-header :title="title" :items="items" />
        <div v-if="content.value" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <were-changes-alert
                            :were-changes="wereChanges"
                            @save="save"
                        />
                        <div class="form-group">
                            <button
                                type="button"
                                class="btn btn-success waves-effect waves-light"
                                @click="save"
                            >
                                <i class="fas fa-save mr-1" />
                                Save updates
                            </button>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <b-form-checkbox
                                    v-model="content.value.enabled"
                                    switch
                                >
                                    Enabled
                                </b-form-checkbox>
                            </div>
                            <div class="form-group col-12">
                                <label>Product</label>
                                <div class="d-flex flex-wrap">
                                    <b-form-select
                                        v-model="
                                            content.value.secondaryProductId
                                        "
                                        :options="secondaryProductOptions"
                                    />
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <label>Condition</label>
                                <b-form-select
                                    v-model="content.value.isByQuantity"
                                    :options="typeOptions"
                                />
                            </div>
                            <div class="form-group col-6">
                                <label>Value</label>
                                <input
                                    v-model.number="content.value.triggerValue"
                                    class="form-control"
                                    type="number"
                                    min="1"
                                />
                            </div>
                            <div class="form-group col-12">
                                <b-form-checkbox
                                    v-model="content.value.allowCouponCode"
                                    switch
                                >
                                    Allow coupon codes
                                </b-form-checkbox>
                            </div>
                            <div class="form-group col-12">
                                <b-form-checkbox
                                    v-model="content.value.enableForOto"
                                    switch
                                >
                                    Enable for OTO
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import ContentMixin from '@router/views/contents/content.mixin.js';

export default {
    page: {
        title: 'Free Gift',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    mixins: [ContentMixin],

    data() {
        return {
            title: 'Free Gift',
            secondaryProducts: [],
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Free Gift',
                    active: true
                }
            ],
            typeOptions: [
                { value: true, text: 'By quantity' },
                { value: false, text: 'By price' }
            ]
        };
    },

    computed: {
        secondaryProductOptions() {
            return this.secondaryProducts.map(product => ({
                text: product.name,
                value: product.id
            }));
        }
    },

    created() {
        this.contentKey = 'free-gift';
        this.contentName = 'Free Gifts';
    },

    async mounted() {
        await this.fetchSecondaryProducts();
    },

    methods: {
        ...mapActions({
            getSecondaryProducts: 'secondaryProducts/index'
        }),

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>
